import { createApp } from 'vue';
window.createApp = createApp;

import Vuex from 'vuex';
window.Vuex = Vuex;

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: false,
    authEndpoint: "/broadcasting/auth",
});

import relativeTime from 'dayjs/plugin/relativeTime';

import dayjs from 'dayjs';
window.dayjs = dayjs;
window.dayjs.extend(relativeTime);

import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";

window.Sentry = Sentry;
window.Integrations = Integrations;

import 'flag-icon-css/css/flag-icon.css';

function iconFormat(icon) {
    var originalOption = icon.element;
    if (!icon.id) {
        return icon.text;
    }
    var $icon = '<i class="icon-' + $(icon.element).data('icon') + '"></i>' + icon.text;

    return $icon;
}

// Switchery
var _componentSwitchery = function () {
    if (typeof Switchery == 'undefined') {
        console.warn('Warning - switchery.min.js is not loaded.');
        return;
    }

    // Initialize multiple switches
    var elems = Array.prototype.slice.call(document.querySelectorAll('.form-check-input-switchery'));
    elems.forEach(function (html) {
        var switchery = new Switchery(html);
    });

    // Colored switches
    $('.form-check-input-switchery-primary').each((index, el) => new Switchery(el, {color: '#2196F3'}));
    $('.form-check-input-switchery-danger').each((index, el) => new Switchery(el, {color: '#EF5350'}));
    $('.form-check-input-switchery-warning').each((index, el) => new Switchery(el, {color: '#FF7043'}));
    $('.form-check-input-switchery-info').each((index, el) => new Switchery(el, {color: '#00BCD4'}));
};

window.setSwitchery = function (switchElement, checkedBool) {
    if ((checkedBool && !switchElement.isChecked()) || (!checkedBool && switchElement.isChecked())) {
        switchElement.setPosition(true);
        switchElement.handleOnchange(true);
    }
}

// Uniform
var _componentUniform = function () {
    if (!$().uniform) {
        console.warn('Warning - uniform.min.js is not loaded.');
        return;
    }

    // Default initialization
    $('.form-check-input-styled').uniform();


    //
    // Contextual colors
    //

    // Primary
    $('.form-check-input-styled-primary').uniform({
        wrapperClass: 'border-primary text-primary'
    });

    // Danger
    $('.form-check-input-styled-danger').uniform({
        wrapperClass: 'border-danger text-danger'
    });

    // Success
    $('.form-check-input-styled-success').uniform({
        wrapperClass: 'border-success text-success'
    });

    // Warning
    $('.form-check-input-styled-warning').uniform({
        wrapperClass: 'border-warning text-warning'
    });

    // Info
    $('.form-check-input-styled-info').uniform({
        wrapperClass: 'border-info text-info'
    });

    // Custom color
    $('.form-check-input-styled-custom').uniform({
        wrapperClass: 'border-indigo-400 text-indigo-400'
    });
};

// Get date ranges from daterangepicker
window.getDateRanges = function (selector) {
    let values = $(selector).val().split(' - ');
    return {
        from: values[0],
        to: values[1]
    };
}

$(document).ready(function () {
    // validate browser version
    window.stack_custom_top = {"dir1": "right", "dir2": "down", "push": "top", "spacing1": 1};

    try {
        eval("null ?? true");
    } catch (err) {
        new PNotify({
            title: "Outdated browser",
            text: "You are using an outdated browser. Please use the latest version of Chrome or Firefox.",
            width: "100%",
            cornerclass: "rounded-0",
            addclass: "stack-custom-top bg-danger border-danger",
            stack: window.stack_custom_top
        });
    }

    $(".select2").each((index, element) => {
        var rdonly = $(element).attr('readonly') ? 'readonly' : false;
        if ($(element).hasClass('select-icons')) {
            $(element).select2({
                templateResult: iconFormat,
                minimumResultsForSearch: Infinity,
                templateSelection: iconFormat,
                disabled: rdonly,
                escapeMarkup: function (m) {
                    return m;
                }
            });
        } else {
            $(element).select2({
                disabled: rdonly,
            });
        }
    });

    _componentSwitchery();
    _componentUniform();

    $('.sidebar-control').click(e => {
        var isClosed = $("#body").hasClass("sidebar-xs") ? 'yes' : '';
        Cookies.set('sidebar-closed', isClosed);
    });

    // Expand active navbar submenu
    $('.nav-item-open').find('.nav-group-sub').slideDown();
});

$.fn.serializeObject = function () {
    var formData = {};
    this.find('[name]').each(function () {
        if ($(this).attr('type') === 'checkbox') {
            formData[this.name.replace('[]', '')] = $(this).is(":checked") ? 1 : 0;
        } else {
            formData[this.name.replace('[]', '')] = $(this).val();
        }
    });
    return formData;
};

// Builds the dom path as a readable breadcrumb
window.getDomPath = (el) => {
    var stack = [];
    while (el.parentNode != null) {
        var sibCount = 0;
        var sibIndex = 0;
        for (var i = 0; i < el.parentNode.childNodes.length; i++) {
            var sib = el.parentNode.childNodes[i];
            if (sib.nodeName == el.nodeName) {
                if (sib === el) {
                    sibIndex = sibCount;
                }
                sibCount++;
            }
        }

        if (el.hasAttribute('id') && el.id != '') {
            stack.unshift(el.nodeName.toLowerCase() + '#' + el.id);
        } else if (el.hasAttribute('class') && el.className != '') {
            stack.unshift(el.nodeName.toLowerCase() + '.' + el.className);
        } else if (sibCount > 1) {
            stack.unshift(el.nodeName.toLowerCase() + ':eq(' + sibIndex + ')');
        } else {
            stack.unshift(el.nodeName.toLowerCase());
        }
        el = el.parentNode;
    }

    return stack.slice(1); // removes the html element
}

$.fn.loading = function () {
    this.block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
        }
    });
    return this;
};

String.prototype.ucFirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

window.titleCase = (string) => {
    return string.replace(/[-_\s]+/g, ' ').split(' ').map(w => w.ucFirst()).join(' ');
}

String.prototype.titleCase = function () {
    return titleCase(this);
}

Date.prototype.toLaravelDate = function () {
    return this.getFullYear() + "-" + ("0" + (this.getMonth() + 1)).slice(-2) + "-" + ("0" + this.getDate()).slice(-2)
        + " " + ("0" + this.getHours()).slice(-2) + ":" + ("0" + this.getMinutes()).slice(-2) + ":" + ("0" + this.getSeconds()).slice(-2);
}

